/* CSS */
import "@css/global.scss";

// Necessary function for Vite HMR
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}



// console.log("Global javascript initialized");


function closeFormModals(){
    // console.log("close");
    let internalForms = document.querySelectorAll('.internal-form');
    if (internalForms){
        internalForms.forEach( form => {
            form.classList.remove('internal-form--active');
            let internal_formEl = form.querySelector('form');
            internal_formEl.reset();
            let successMsg = document.querySelector('.ff-form-success');
            if (successMsg) {
                successMsg.remove();
            }
        })
        document.body.classList.remove('body--internal-form-open');
    }

}

  // FORM MODALS
 
  let internalFormBGs = document.querySelectorAll('.internal-form__bg');
  let internalFormCloseBtns = document.querySelectorAll('.internal-form__close-button');

  if (internalFormBGs?.length > 0){
    internalFormBGs.forEach( bg => {
        bg.addEventListener('click', function(){
            closeFormModals();
          })
    })
  
  }
  if (internalFormCloseBtns?.length > 0){
    internalFormCloseBtns.forEach( btn => {
        btn.addEventListener('click', function(){
            closeFormModals();
        })
        btn.addEventListener('keydown', function(e){
            if (e.key === 'Enter'){
                closeFormModals();
            }
        })
    })

  }

